import * as React from "react"
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Seo from '../components/Seo';
import About from '../components/About';
import Studies from '../components/Studies';
import Contact from '../components/Contact';
import Doctoralia from '../components/Doctorialia';


const IndexPage = () => {
  return (
      <Layout>
        <Hero/>
        <About/>
        <Studies/>
        <Doctoralia/>
        <Contact/>
      </Layout>
  )
}

export default IndexPage

export function Head(){
  return(
    <Seo />
  )


} 