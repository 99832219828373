import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export default function SEO({ title, description, keywords, children }) {
  const { title: defaultTitle, description: defaultDescription, keywords: defaultKeywords, siteUrl } = useSiteMetadata()
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    url: `${siteUrl}` || ``,
  }

  return (
    <>
      <link rel="alternate" href={seo.url} hreflang="es-mx" />
            <meta content="es-MX" http-equiv="content-language" />
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            <meta name="keyphrases" content={seo.keywords} />
            <meta name="generator" content={seo.description} />
            <meta name="classification" content={seo.description} />
            <meta name="subject" content={seo.description} />
            <meta name="geo.region" content="MX" />
            <meta name="geo.region" content="Mexico" />
            <meta name="geo.placename" content="Mexico" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="AdsBot-Google" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <meta name="robots" content="index, follow" />
    </>
  )
}