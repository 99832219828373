import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function About(){

    return(
        <div id='about-container' className="max-w-full mx-6 my-24 flex flex-col 
        md:flex-row md:mx-10 md:mt-16 lg:mx-16 lg:mt-24">
            <div className="font-dosis w-full md:max-w-[70%]">
                <div className="text-lg md:mr-10 lg:text-2xl">Soy la Dra. Ana Yatzikuri Torres González, 
                con 11 años de estudios profesionales. Soy médico especialista en 
                Audiología, Otoneurología y Foniatría con alta especialidad en Foniatría. 
                Egresé de la licenciatura de Médico Cirujano y partero de la Universidad 
                de Guadalajara (UdeG), después realicé la especialidad en Audiología, 
                Otoneurología y Foniatría en la Ciudad de México en el Instituto Nacional 
                de Rehabilitación donde posteriormente realicé una alta especialidad en 
                Diagnóstico y Tratamiento Patologías Foniátricas Complejas, ambos avalados 
                por la Universidad Nacional Autónoma de México (UNAM).</div>
                <p className="italic font-semibold my-12 lg:text-xl">Certificada por el 
                Consejo Mexicano Comunicación, Audiología, Otoneurología y Foniatría, A.C.</p>
            </div>
            <div className="w-full my-12 md:max-w-[30%]">
                <StaticImage className="rounded-full shadow-xl" src='../images/dra_2.jpg' alt="Dra. Ana Y. Torres" />
            </div>
        </div>
    );
}