import React from 'react';

export default function Comments(){

    const loadComments = ($_x,_s,id) => {
        var js,fjs = $_x.getElementsByTagName(_s)[0];
        if(!$_x.getElementById(id))
        {
            js = $_x.createElement(_s);
            js.id = id;
            js.src = "//platform.docplanner.com/js/widget.js";
            fjs.parentNode.insertBefore(js,fjs);
        }
    }

    if(typeof document !== 'undefined'){
        loadComments(document, "script", "zl-widget-s")
    }

    return(
        <div id="Comments" 
        className="flex justify-center my-12 mx-6">
            <a id="zl-url" className="zl-url border-none" href="https://www.doctoralia.com.mx/ana-yatzikuri-torres-gonzalez-2/audiologo-foniatra/guadalajara" rel="nofollow" data-zlw-doctor="ana-yatzikuri-torres-gonzalez-2" data-zlw-type="big" data-zlw-opinion="true" data-zlw-hide-branding="true">Ana Yatzikuri Torres González - Doctoralia.com.mx</a>
        </div>
    )
}