import React  from 'react';
import { StaticImage } from 'gatsby-plugin-image';


export default function Contact(){
    
    return (
        <div id="Contact" className="max-w-full flex flex-col mt-14 md:mt-8 md:flex-row mb-14 md:justify-center">
            <div className="md:w-[30%] md:max-w-[40%] mx-6 flex flex-col gap-8 justify-start md:my-16 font-dosis">
                <div className="flex flex-row gap-4">
                    <StaticImage className='w-8 md:w-9' imgClassName="phone-img" src='../images/phone.png' alt='phone-icon'/>
                    <p className='text-lg'>3320218179</p>
                </div>
                <div className="flex flex-row gap-4">
                    <StaticImage className='w-10 md:w-12' imgClassName="mail-img" src='../images/mail.png' alt='mail-icon'/>
                    <p className='text-lg'>yatzikuri@hotmail.com</p>
                </div>
                <div className="flex flex-row gap-4">
                    <StaticImage className='w-10 md:w-12' imgClassName="marker-img" src='../images/marker.png' alt='map-mark-icon'/>
                    <p className='text-sm lg:text-lg'>Lope de Vega 24 int. 205 (1º Piso) Col. Arcos Vallarta, Gdl.</p>
                </div>
            </div>
    
            <div className="md:max-w-[60%] mx-6 mt-16">
                <StaticImage className="map_img md:w-full" src='../images/map2.png' />
            </div>
        </div>
    );
}