import React from 'react';
import brain_icon from '../images/brain.png';
import { StaticImage } from 'gatsby-plugin-image';

export default function Studies () {

    return(
        <div id='studies-container' className="max-w-full m-6 flex flex-col justify-center font-dosis">
            <h1 className='text-2xl font-semibold my-12 text-center lg:text-3xl'>Atención de Padecimientos</h1>
            <div id="Suffering" className="flex flex-col md:flex-row justify-center">
                <div className="w-full md:max-w-[40%] mb-6 lg:text-xl">
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Hipoacusia/Problemas de audición en adultos y niños </p>
                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Acúfeno/Zumbidos de oído</p>
                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Adaptación de auxiliares auditivos</p>

                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Disfagia/Problemas de deglución</p>
                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Disfonía/Problemas de voz</p>
                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Tartamudez</p>
                    </div>
                    <div className="flex flex-row">
                        <img className='m-4' src={brain_icon} alt="brain_icon"></img>
                        <p className='m-4 grow'>Retardos de lenguaje</p>
                    </div>
                </div>
                <div className="w-full md:max-w-[30%]">
                    <StaticImage className="rounded-full shadow-xl" src='../images/auxiliar.png' alt="atention_img" />
                </div>
            </div>
            <h1 className='text-2xl font-semibold my-12 text-center lg:text-3xl'>Estudios</h1>
            <div id="Studies" className="flex flex-col md:flex-row justify-center mb-6 lg:text-xl">
                <div className="w-full md:max-w-[30%] md:mr-32">
                    <StaticImage className="rounded-full shadow-xl" src='../images/studies_image_3.png' alt="studies_img" />
                </div>
                <div className="w-full flex flex-col mt-6 md:flex-row md:max-w-[40%]">
                    <div className="w-full">
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Potenciales Auditivos de Tallo Cerebral</p>
                        </div>
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Potenciales Auditivos de Estado Estable</p>
                        </div>
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Audiometría</p>
                        </div>
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Impedanciometría</p>
                        </div>
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Tamiz Auditivo Neonatal</p>
                        </div>
                        <div className="flex flex-row">
                            <img className="m-4" src={brain_icon} alt="brain_icon"></img>
                            <p className='m-4 grow'>Estudios de deglución (FEES)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}