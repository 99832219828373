import React from 'react';
import image from '../images/hero.jpg'

export default function Hero(){

    const style = {
        'backgroundImage': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`,
    }

    return (
        <div id='hero-container' className="bg-cover bg-center h-screen w-screen flex flex-col justify-center" style={style}>
            <div className="mx-4 flex flex-col gap-6">
                <h1 className='text-3xl text-center align-middle 
                text-white font-dosis lg:text-5xl'>Audiología, Otoneurología y Foniatría</h1>
                <p className="text-2xl text-center align-middle 
                text-white font-dosis lg:text-4xl">Alta especialidad en Diagnostico y 
                Tratamiento de Patologías Foniátricas Complejas</p>
                <p className="text-xl text-center align-middle 
                text-white font-dosis italic lg:text-3xl">Dra. Ana Yatzikuri Torres Gonzalez</p>
            </div>
        </div>
    );
}