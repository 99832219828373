import React from "react";
import Comments from '../components/Comments';
import Calendar from '../components/Calendar';
import bg_top_img from '../images/top_background.png';
import bg_bottom_img from '../images/bottom_background.png';

export default function Doctoralia(){


    return(
        <div id="Doctoralia"
        className="max-w-full flex flex-col bg-teal-500">
            <img className="w-full" src={bg_top_img} alt="bg_top"/>
            <div 
            className="flex flex-col md:flex-row justify-center gap-8">
                <Comments/>
                <Calendar/>
            </div>
            <img src={bg_bottom_img} alt="bg_bottom"/>
        </div>
    )
}